*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.section2{
    padding: 50px 150px;
}
.section2 .info{
    display: flex;
    flex-direction: row;
    gap: 10%;
}

.info .infopart-1 p{
    box-sizing: border-box;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 1.8em;
    color: rgb(125, 83, 0);
}
.all-section h1{
    color: rgb(125, 83, 0);
    font-size: 5em;
    font-weight: 900;
    padding-top: 50px;
    padding-left: 150px;
}
.all-section span{
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(125, 83, 0);
}

.image2{
    width: 500px;
    height: auto;
    border-radius: 40px;
}


@media (max-width: 1328px) {
    .all-section h1{
        font-size: 3em;
        padding-left: 0px;
        text-align: center;
    }
    .section2 .info{
        flex-direction: column;
        align-items: center;
    }
    
    .info .image2{
        margin-top: 50px;
    }
    .info .image2{
        width: 700px;
        height: auto;
    }
}

@media (max-width: 1040px) {
    .section2 .info{
        flex-direction: column;
    }
    .info p{
        text-align: center;
        font-size: 1em !important;
    }
    
}

@media (max-width: 500px) {
    .all-section h1{
        font-size: 2em;
        padding-left: 0px;
        text-align: center;
    }
    .section2 h1{
        margin-left: 20px;
        margin-right: 20px;
    }
    .info p{
        text-align: center;
        font-size: 0.3em;
    }
    .section2{
        padding: 0 25px;
        padding-top: 0;
    }
    .info .infopart-1 p{
        text-align: center;
        font-size: 1.4em;
    }

    .info .image2{
        width: 330px !important;
        height: auto;
    }
}

@media (max-width: 700px) {
    .info .image2{
        width: 450px;
        height: auto;
    }
}