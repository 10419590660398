.about-background{
    background: url(../../images/background222222.png);
    height: 100vh;
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.about-background h1{
    font-size: 5em;
    color: transparent;
    font-weight: 900;
    color: white;
}
.about-container{
    padding: 100px 200px;
}
.about-container-2{
    padding: 0 200px;
    
}

.about-container p{
    font-size: 3em;
    text-align: center;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgb(85, 55, 0);
}

.about-container-2 h1{
    font-size: 3em;
    color: rgb(85, 55, 0);
    font-weight: 900;
    text-align: center;
    padding-bottom: 50px;
}
.image-container-2{
    display: flex;
    flex-direction: row;
    gap: 10%;
}


@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,800);
figure.snip1157 {
  font-family: 'Raleway', Arial, sans-serif;
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 220px;
  max-width: 310px;
  width: 100%;
  color: #333;
  text-align: left;
  box-shadow: none !important;
}
figure.snip1157 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}
figure.snip1157 img {
  max-width: 100%;
  vertical-align: middle;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  margin: 40px 0 0 10px;
}
figure.snip1157 blockquote {
  display: block;
  border-radius: 8px;
  position: relative;
  background-color: #fafafa;
  padding: 25px 50px 30px 50px;
  font-size: 0.8em;
  font-weight: 500;
  margin: 0;
  line-height: 1.6em;
  font-style: italic;
}
figure.snip1157 blockquote:before,
figure.snip1157 blockquote:after {
  font-family: 'FontAwesome';
  content: "\201C";
  position: absolute;
  font-size: 50px;
  opacity: 0.3;
  font-style: normal;
}
figure.snip1157 blockquote:before {
  top: 25px;
  left: 20px;
}
figure.snip1157 blockquote:after {
  content: "\201D";
  right: 20px;
  bottom: 0;
}
figure.snip1157 .arrow {
  top: 100%;
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid #fafafa;
  margin: 0;
  position: absolute;
}
figure.snip1157 .author {
  position: absolute;
  bottom: 45px;
  padding: 0 10px 0 120px;
  margin: 0;
  text-transform: uppercase;
  color: #ffffff;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
figure.snip1157 .author h5 {
  opacity: 0.8;
  margin: 0;
  font-weight: 800;
}
figure.snip1157 .author h5 span {
  font-weight: 400;
  text-transform: none;
  padding-left: 5px;
}
.about-container-3 h1{
    font-size: 3em;
    color: rgb(63, 41, 1);
    font-weight: 900;
    text-align: center;
    padding: 50px 0;
}

.testimonials{
    padding: 50px 0;
    background-color:  rgb(131, 131, 130);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: wrap;
    margin: 0;
    height: 100%;
    margin-bottom: 5px;
}
@media (max-width: 1284px){
    .image-container-2{
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10%;
    }
    .image-container{
        padding-bottom: 20px;
    }
}
@media  (max-width: 840px){
    .image-container-2{
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10%;
    }
    .image-container{
        padding-bottom: 20px;
    }
    .about-container-2 h1{
        text-align: center;
    }
    .about-container p{
        font-size: 2em;
        text-align: center;
    }
    .about-container-2{
        padding: 0px 0px;
    }
    
}
@media (max-width: 600px){
    .about-container{
        padding: 50px 100px;
    }
    .about-container p{
        font-size: 1.5em;
        text-align: center;
    }
    
}

@media (max-width: 450px){
    .about-container-2{
        padding: 0px 0px;
    }
}