* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .title2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  .title2 h1{
    font-size: 5em;
    font-weight: 900;
    text-align: center;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(125, 83, 0);
  }
  
  .title2 span{
    color: rgb(125, 83, 0);
    text-align: center;
  }



.title2 h1:first-of-type {
    color: transparent;

}

.title2 h1:last-of-type {
    color: transparent;
}

@media (max-width: 600px) {
    .title2 h1 {
        font-size: 2em;
    }
}
@media (max-width: 700px){
  .title2 h1{
    padding-top: 40px;
  }
}


.content3{
    padding: 0 4%;
    padding-top: 50px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.card1{
    width: 250px;
    height: 250px;
    background: #616060;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.24);
    border: 2px solid rgba(7,7,7,0.12);
    font-size: 16px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.3s ease;
}
.icon{
    margin: 0 auto;
    width: 100%;
    height: 80px;
    max-width: 80px;
    background: linear-gradient(90deg, #713b02 2%, #653c02 40%, rgba(0,0,0,0.28) 68%);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-position: 0px;
    background-size: 200px;
    transition: all 0.8s ease;
}

.material-icons{
    font-size: 36px;
    color: white;
}

.card1 .title{
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: 30px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 4ppx;
}
.card1 .text{
    width: 80%;
    margin: 0 auto;
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
    font-weight: 200;
    letter-spacing: 2px;
    opacity: 0;
    color: #fff;
    max-height: 0;
    transition: all 0.3s ease;
}
.card1:hover{
    height: 270px;
}
.card1:hover .text{
    transition: all 0.3s ease;
    opacity: 1;
    max-height: 40px;
}
.card1:hover .icon{
    background-position: -120px;
    transition: all 0.3s ease;
}
.card1:hover .icon i{
    background: linear-gradient(90deg, #FF7E7E, #FF4848);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 1;
    transition: all 0.3s ease;
}



:root{
    --background-dark: #2d3548;
    --text-light: rgba(255,255,255,0.6);
    --text-lighter: rgba(255,255,255,0.9);
    --spacing-s: 8px;
    --spacing-m: 16px;
    --spacing-l: 24px;
    --spacing-xl: 32px;
    --spacing-xxl: 64px;
    --width-container: 1200px;
  }
  
  
  .hero-section{
    align-items: flex-start;
    background-image: white;
    display: flex;
    min-height: 100%;
    justify-content: center;
    padding: var(--spacing-xxl) var(--spacing-l);
  }
  
  .card-grid{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: var(--spacing-l);
    grid-row-gap: var(--spacing-l);
    max-width: var(--width-container);
    width: 100%;
  }
  
  @media(min-width: 540px){
    .card-grid{
      grid-template-columns: repeat(2, 1fr); 
    }
  }
  
  @media(min-width: 960px){
    .card-grid{
      grid-template-columns: repeat(4, 1fr); 
    }
  }
  
  .card{
    list-style: none;
    position: relative;
  }
  
  .card:before{
    content: '';
    display: block;
    padding-bottom: 150%;
    width: 100%;
  }
  
  .card__background{
    background-size: cover;
    background-position: center;
    border-radius: var(--spacing-l);
    bottom: 0;
    filter: brightness(0.75) saturate(1.2) contrast(0.85);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: center;
    trsnsform: scale(1) translateZ(0);
    transition: 
      filter 200ms linear,
      transform 200ms linear;
  }
  
  .card:hover .card__background{
    transform: scale(1.05) translateZ(0);
  }
  
  .card-grid:hover > .card:not(:hover) .card__background{
    filter: brightness(0.5) saturate(0) contrast(1.2) blur(20px);
  }
  
  .card__content{
    left: 0;
    padding: var(--spacing-l);
    position: absolute;
    top: 0;
  }
  
  .card__category{
    color: var(--text-light);
    font-size: 0.9rem;
    margin-bottom: var(--spacing-s);
    text-transform: uppercase;
  }
  
  .card__heading{
    color: var(--text-lighter);
    font-size: 1.9rem;
    text-shadow: 2px 2px 20px rgba(0,0,0,0.2);
    line-height: 1.4;
    word-spacing: 100vw;
  }