@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

section{
    padding: 100px 200px;
}

.home{
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column ;
    background-image: url(../../images/paradise-back-3.jpg);
    background-size: cover;
}
.container{
    backdrop-filter: blur(5px);
    padding: 0 20px;
    padding-bottom: 30px;
    border-radius: 25px;
    width: 700px;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}

.home .content{
    z-index: 888;
    color: #fff;
    width: 60%;
}

.home .content h1{
    font-size: 4em;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 5px;
    line-height: 75px;
    margin-bottom: 40px;
}

.home .content p {
    font-size: 1.6em;
    font-weight: 800;
    margin-bottom: 40px;
    color: white;
}

.home .content a{
    background: #fff;
    padding: 15px 35px;
    color: #924e06;
    font-size: 1.1em;
    font-weight: 500;
    text-decoration: none;
    border-radius: 2px;
}

.home .media-icons{
    z-index: 888;
    position: absolute;
    right: 30px;
    display: flex;
    flex-direction: column;
    transition: 0.5s ease;
}

.home .media-icons a{
    color: #fff;
    font-size: 1.6em;
    transition: 0.3s ease-in;
}

.home .media-icons a:not(:last-child){
    margin-bottom: 20px;
}

.home .media-icons a:hover{
    transform: scale(1.3);
}

@media  (max-width: 1040px){
    section{
        padding: 100px 20px;
    }

    .home .media-icons{
        right: 15px;
    }
    .container{
        padding: 20px 20px;
        border-radius: 25px;
        width: 700px;
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    }
}

@media  (max-width: 790px){
    section{
        padding: 100px 20px;
    }
    
    .container{
        
        width: 450px;
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    }
    
    .home .content{
        z-index: 888;
        color: #fff;
        width: 60%;
    }
    
    .home .content h1{
        font-size: 2.5em;
        font-weight: 900;
        line-height: 50px;
        margin-bottom: 20px;
    }
    
    .home .content p {
        font-size: 1.2em;
        font-weight: 800;
        margin-bottom: 30px;
        color: white;
    }
    
    .home .content a{
        background: #fff;
        padding: 10px 25px;
        color: #924e06;
        font-size: 1.1em;
        font-weight: 500;
        text-decoration: none;
        border-radius: 2px;
    }
    
    .home .media-icons{
        z-index: 888;
        position: absolute;
        bottom: 0px;
        
        display: flex;
        flex-direction: row;
        transition: 0.5s ease;
    }
    
    .home .media-icons a{ 
        margin-left: 15px;
    }
}

@media  (max-width: 380px){
    .container{
        width: 330px !important;
    }
    
}

@media  (max-width: 473px){
    .container{
        width: 355px;
    }
    
}



