*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.section3{
    padding: 0px 150px;
    padding-bottom: 100px;
}

.section3 .info-section3{
    display: flex;
    flex-direction: row;
    gap: 10%;
}

.info-section3 img{
    border-radius: 40px;
    width: 450px;
    height: auto;
}

.info-section3 p{
    font-size: 2em;
    color: rgb(125, 83, 0);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}


@media (max-width: 1328px) {
    .section3 .info-section3{
        flex-direction: column;
        align-items: center;
    }
    .info-section3 img{
        width: 700px;
        height: auto;
    }
    .info-section3 p{
        text-align: center;
        margin-top: 50px;
    }
} 

@media (max-width: 700px) {
    
    .info-section3 img{
        width: 450px;
        height: auto;
    }
    .info-section3 p{
        text-align: center;
        margin-top: 50px;
        font-size: 1em;
    }
    .section3{
        padding: 0 25px;
    }
}

@media (max-width: 500px) {
    
    .info-section3 img{
        padding-top: 10px;
        width: 330px;
        height: auto;
    }
    .info-section3 p{
        text-align: center;
        margin-top: 50px;
        font-size: 1em;
    }
    .section3{
        padding: 0 25px;
    }
}