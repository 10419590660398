.rooms-background{
    background: url(../../images/room4.jpg);
    height: 100vh;
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.rooms-background h1{
    font-size: 5em;
    color: transparent;
    font-weight: 900;
    color: white;
}



  .text h1{
    font-size: 5em;
    font-weight: 900;
    -webkit-text-stroke: 1px rgb(125, 83, 0);
    color: transparent;
    text-align: center;
  }
  .text h1 span{
    color:rgb(125, 83, 0) !important;
  }
  .container-carousel h1{
    color: rgb(125, 83, 0);
  }

  .container-text{
    padding-left: 40px;
  }
  .carousel {
    margin: 50px 0;
    height: 500px;
    display: flex;
    flex-direction: row;
  }
  .space-left{
    display: none;
  }
  .space-right{
    display: none;
  }

  .carouselInner{
    flex: 60%;
    border-radius: 40px;
    width: 100%;
    height: 100%;
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
  }

  .container-carousel{
    flex:40%;
    display: flex;
    flex-direction: column;
    gap: 3%;
    padding-left: 40px;
    
  }
  .row11{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .row11 p{
    margin-right: 10px;
  }
  .row2 p{
    margin-right: 10px;
  }
  .row3 p{
    margin-right: 10px;
  }
  .row2{
    display: flex;
    flex-direction: row;
  }
  .row3{
    display: flex;
    flex-direction: row;
  }
  .carouselInner .left{
    flex: 10%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.6);
    display: grid;
    place-items: center;
    border-bottom-left-radius: 40px;
    border-top-left-radius: 40px;
    color:white;
  }

  .carouselInner .right{
    flex: 10%;
    height: 100%;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    background-color: rgb(0, 0, 0, 0.6);
    display: grid;
    place-items: center;
    color: white;
  }
  .carouselInner .center{
    flex: 80%;
    height: 100%;
    
  }
  .bookingbutton{
    padding-top: 20px;
    
  }
  .bookbutton2{
    max-width: fit-content;
    padding: 15px 20px;
    text-decoration: none;
    color: white;
    background-color: rgb(125, 83, 0);
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 1020px) {
    .carousel {
        padding: 0 100px;
        margin: 0 0;
        height: 760px;
        display: flex;
        flex-direction: column;
      }
    .carouselInner{
        flex: 60%;
        border-radius: 40px;
        width: 100%;
        height: 100%;
        background-size:cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
      }
      
      .container-carousel{
        text-align: center;
        flex:40%;
        display: flex;
        flex-direction: column;
        gap: 0;
        padding-left: 40px;
      }
      .row11, .row2, .row3{
        justify-content: center;
      }

      .row1 .text h1{
        font-size: 3.5em !important;
        color: transparent;
        font-weight: 900;
        color: white;
    }
    
  }
  @media (max-width: 612px){
    .carouselInner{
      margin-top: 20px;
    }
    .carousel {
        padding: 0 50px;
        margin-bottom: 50px;
        height: 600px;
        display: flex;
        flex-direction: column;
      }
      .container-carousel{
        padding-left: 0;
      }
    .container-carousel p{
        font-size: 12px;
    }
    .row11 p{
        font-size: 12px;
    }
    .row2 p{
        font-size: 12px;
    }
    .row3 p{
        font-size: 12px;
    }
  }

  @media (max-width: 375px){

  }