@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
header{
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 200px;
    transition: 0.5s ease;
}

header .navigation .navigation-items a{
    position: relative;
    color: rgb(255, 255, 255);
    font-size: 1em;
    font-weight: 500;
    text-decoration: none;
    margin-left: 30px;
    transition: 0.3s ease;
}
.logo img {
    width: 200px;
    height: auto;
}
header .navigation .navigation-items a:before{
    content: '';
    position: absolute;
    background: rgb(255, 255, 255);
    width: 0;
    height: 3px;
    bottom: 0;
    left: 0;
    transition: 0.3s ease;
}

header .navigation .navigation-items a:hover:before{
    width: 100%;
}
.menu-btn{
    display: none;
}
.bookbutton{
    background-color: rgb(101, 69, 0);
    padding: 10px 15px;
}




@media (max-width: 1040px) {
    header{
        padding: 12px 20px;
    }
    header .navigation {
        display: none;
    }
    header .navigation.active{
        position: fixed;
        width : 100%;
        height: 100vh;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(1,1,1,0.5);
    }

    header .navigation .navigation-items a{
        color: #222;
        font-size: 1.2em;
        margin: 20px;
    }
    header .navigation .navigation-items a:before{
        background: #222;
        height: 5px;
    }
    header .navigation.active .navigation-items{
        background: #fff;
        width: 600px;
        max-width: 600px;
        margin: 20px;
        padding: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 5px;
        box-shadow: 0 5px 25px rgb(1 1 1 / 20%);
    }
    .menu-btn{
        color: white;
        display: flex;
        width: 40px;
        height: 40px;
        cursor: pointer;
        transition: 0.3s ease;
    }
    .menu-btn.active{
        z-index: 999;
        transition: 0.3s ease;
    }
}